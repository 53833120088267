<template>
<div class="itinerary-list">
    <div class="search-bar">
        <div class="p-input-icon-left search-field">
            <i class="pi pi-search" />
            <InputText
                type="text"
                placeholder="Search by name"
                v-model="filters['global'].value"
            />
        </div>
        <router-link to="/itinerary/add-itinerary" target="_blank"> 
            <Button v-if="can('create', 'itinerary')" label="Add" class="p-button-sm" icon="pi pi-plus" />
        </router-link>
    </div>   
    
    <div class="content-section implementation">
        <div class="itinerary-datatable card">
            <DataTable
                :value="itineraries"
                responsiveLayout="scroll"
                scrollHeight="240px"
                v-model:selection="selectedItinerary" 
                selectionMode="single"
                @rowSelect="onRowSelect"
                dataKey="id"
                v-model:filters="filters"
                :globalFilterFields="['itinerary_name']"
            >
                <Column field="id" header="ID" :style="{'min-width':'70px'}"></Column>
                <Column field="itinerary_name" header="Name" :style="{'min-width':'120px'}"></Column>

                <Column v-if="can('update', 'itinerary') || can('delete', 'itinerary')" :exportable="false" :style="{'min-width':'90px', 'padding':'0.4rem 0.5rem', 'text-align':'right'}">
                    <template #body="slotProps">
                        <router-link :to="'/itinerary/update-itinerary/'+slotProps.data.id" target="_blank"> 
                            <Button v-if="can('update', 'itinerary')" icon="pi pi-pencil" class="p-button-rounded p-button-outlined p-button-success small-datatable-button p-mr-2" @click="onItineraryEdit(slotProps.data)" />
                        </router-link>
                        <Button v-if="can('delete', 'itinerary')" icon="pi pi-trash" class="p-button-rounded p-button-outlined p-button-danger small-datatable-button p-mr-2" @click="confirmDeleteItinerary(slotProps.data)" />
                    </template>
                </Column>
            </DataTable>
            <ConfirmDialog></ConfirmDialog>
        </div>
    </div>
    <div v-for="(itinerary, index) of itineraryDetail" :key="itinerary.id"> 
        <Panel :toggleable="true">
            <template #header>
                <div style="font-weight: 500; text-transform: uppercase;">
                    {{'Day ' + (index+1) + ': ' + itinerary.itin_outline}}
                </div>
            </template>
            <div class="itineray-detail">
                <span v-html="itinerary.itin_detail"></span>
            </div>
        </Panel>
    </div>
    <div class="itineray-detail" style="padding-bottom: 2rem">
        <DataTable v-if="itineraryDetail.length > 0" :value="itineraryInclusive" dataKey="id" showGridlines responsiveLayout="scroll">
            <Column header="Inclusive of" :style="{'vertical-align':'top', 'width':'50%'}">
                <template #body="slotProps">
                    <span v-html="slotProps.data.inclusive"></span>
                </template>
            </Column>
            <Column header="Exclusive of" :style="{'vertical-align':'top', 'width':'50%'}">
                <template #body="slotProps">
                    <div v-html="slotProps.data.exclusive"></div>
                </template>
            </Column>
        </DataTable>
    </div>
</div>
</template>

<script>
import { ref, onMounted } from 'vue';
import { useConfirm } from "primevue/useconfirm";
import { useToast } from "primevue/usetoast";
import { useAbility } from '@casl/vue';
import { FilterMatchMode } from 'primevue/api';
import ItineraryService from '../service/ItineraryService';

export default {
    setup() {
        const { can } = useAbility()
        const confirm = useConfirm()
        const toast = useToast()
        let loading = ref(false)
        let itineraries = ref([])
        let selectedItinerary = ref()
        let itineraryDetail = ref([])
        let itineraryInclusive = ref([])
        const filters = ref({
            'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
        });

        //On Create
        const itinService = ref(new ItineraryService())

        onMounted(() => {
            getItinerary()
        })

        //Methods
        const onRowSelect = (event) => {
            getItineraryDetail(event.data.id)
        }

        const getItinerary = () => {
            itinService.value.getItinerary().then((data) => {
                if(!data.errorResponse) {
                    itineraries.value = data;
                    loading.value = false;
                }
            });
        }

        const getItineraryDetail = (itineraryID) => {
            itinService.value.getItineraryDetail(itineraryID).then(data => {
                if(!data.errorResponse) {
                    itineraryDetail.value = data.itin_Data
                    itineraryInclusive.value = data.itin_Inclusive
                }
            })
        }

        const onItineraryEdit = (data) => {
            selectedItinerary.value = data
            getItineraryDetail(data.id)
        }

        const confirmDeleteItinerary = (data) => {
            confirm.require({
                message: 'Are you sure you want to delete "'+ data.itinerary_name + '" itinerary?',
                header: 'Delete Confirmation',
                icon: 'pi pi-info-circle',
                acceptClass: 'p-button-danger',
                accept: () => {
                    const itinerary_id = data.id
                    itinService.value.deleteItinerary(itinerary_id).then((data) => {
                        if(!data.errorResponse){
                            itineraries.value = itineraries.value.filter(val => val.id !== itinerary_id);
                            itineraryDetail.value.length = 0
                        } else {
                            toast.add({severity:'warn', summary: 'Delete Error ' + data.status, detail: data.errorResponse, life: 5000});
                        }
                    })
                }
            });
        }

        return {
            can,
            filters,
            itineraries,
            selectedItinerary,
            onRowSelect,
            itineraryDetail,
            onItineraryEdit,
            confirmDeleteItinerary,
            itineraryInclusive,
        }
    },
}
</script>

<style lang="scss" scoped>
.itinerary-datatable {
    height: 250px;
}
.p-panel {
    margin-bottom: 10px;
}
</style>